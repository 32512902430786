const CONSTANTS = {
  JWT: "JWT",
  REMEMBER_ME: "REMEMBER_ME",

  APP_NAME: "Smarter IPTV Players",

  API_HOST: "https://api.smartiptvplayers.com",

  APP_COLOR: "black",
  LINK_COLOR: "white",
};

export default CONSTANTS;
